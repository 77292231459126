import React from "react"

import Breadcrumb from "../../components/breadcrumb"
import Grid from "../../components/grid"
import HoelderlinLeuchtetBanner from "../../components/hoelderlin-leuchtet-banner"
import Layout from "../../layouts/default"
import PageTitle from "../../components/page-title"
import Paragraph from "../../components/paragraph"
import Seo from "../../components/seo"
import Stack from "../../components/stack"
import TeaserBox from "../../components/teaser-box"

const IndexPage = () => {
  return (
    <Layout backdrop="workshops">
      <Seo
        title="Für Schulen"
        description="Anknüpfend an die Dauerausstellung bietet der Hölderlinturm ein umfangreiches Programm aus Führungen und Workshops für Schülerinnen und Schüler aller Schularten und Jahrgangsstufen."
      />

      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Für Schulen",
              link: "/fuer-schulen",
            },
          ]}
        />
        <PageTitle>Für Schulen</PageTitle>
        <Paragraph dropcap={true}>
          Als außerschulischer Lernort bietet der Hölderlinturm ein
          umfangreiches Programm für Schulklassen aller Schularten und
          Jahrgangsstufen. Die interaktiv gestalteten Angebote eröffnen Zugänge
          zu Hölderlins Leben und Werk und vermitteln poetische Prinzipien, die
          literarischen Texten zugrunde liegen. Sie ermutigen zum spielerischen
          Umgang mit Sprache und Literatur und werfen einen Blick in die
          Dichter-Werkstatt: auf die Entstehung und Machart der Poesie.
        </Paragraph>
        <Stack space={24}>
          <Grid columns={[null, null, 2]} space={6}>
            <TeaserBox
              title="Digitale Kurse"
              headingElement="h2"
              description={`Lust auf Lyrik? Unsere interaktiven Online-Kurse ermöglichen Annäherungen an Hölderlins Sprachkunstwerke. Im Mittelpunkt steht jeweils ein Gedicht von Hölderlin, das aus verschiedenen Perspektiven erschlossen wird. Vom Grundschul- bis ins Erwachsenen-Alter ist für Alle etwas dabei!`}
              to="/kurse"
              cta="Zur Digitalen Ausstellung"
            />
            <TeaserBox
              title="Angebote vor Ort"
              headingElement="h2"
              description="Verschiedene thematische Streifzüge durch den Turm, in dem Friedrich Hölderlin die zweite Hälfte seines Lebens verbracht hat, laden Schulklassen aller Jahrgangsstufen ein, den Dichter und seine Werken kennenzulernen."
              to="/fuer-schulen/fuehrungen"
              cta="Führungen im Überblick"
            />
          </Grid>
          <HoelderlinLeuchtetBanner />
        </Stack>
      </Stack>
    </Layout>
  )
}

export default IndexPage
